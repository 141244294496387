<template>
    <div class="featuredBlogsContainer">
        <div class="elementContainer featuredBlogs">
            <h2>Blogs Recientes</h2>
            <div class="blogsContainer">
                <div class="blogItem" v-for="articulo in dosMasRecientes" :key="articulo.id">
                    <router-link :to="articulo.link" class="blogCard">
                        <div class="blogImageContainer"><img class="blogImage" :src="require(`@/assets/blogAssets/${articulo.image}`)"></div>
                        <div class="blogInfoContainer">
                            <h4>{{articulo.title}}</h4>
                            <span>{{articulo.description}}</span>
                        </div>
                    </router-link>
                </div>
            </div>
            <router-link to="/articulos"><button class="button_style_1">Ver Más</button></router-link>
        </div>
    </div>
</template>

<script setup>
import articulos from '@/data/articulos.json';
import { computed, onMounted } from 'vue';

const dosMasRecientes = computed(() =>{
    const listado = [...articulos].reverse();
    return listado.slice(0,2);
})

</script>

<style lang="scss" scoped>

/* ------------------ FEATURED BLOGS -------------------------*/


@use "@/styles/styles.scss" as styles;

.featuredBlogsContainer{

justify-content: center;
background-color: #F1F1F1 !important;


.featuredBlogs{
    @include styles.regularContainer();
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 200px 50px 100px 50px;
    max-width: 100%;

    .blogCard{
        background-color: #fff;
    }

    .blogsContainer{
        padding-top: 50px;
        padding-bottom: 0px;
    }
}
}

@media (max-width: 768px){

    .featuredBlogsContainer .blogsContainer{
        padding: 0px;
    }

}

</style>