import { createRouter, createWebHistory } from "vue-router"
import Home from '@/views/Home.vue'
import Articulos from '@/views/Articulos.vue'
import Entrevistas from "@/views/Entrevistas.vue"
import ASolAs from "@/views/articulos/A-Sol-As.vue";
import ApuntesSobreTria from "@/views/articulos/Apuntes-Sobre-Tria.vue";
import ElHabito from "@/views/articulos/El-Habito-Te-Hace-Monje.vue";
import LoIdealYLoReal from "@/views/articulos/Lo-Ideal-Y-Lo-Real.vue";
import EntrenoSientoEscribo from "@/views/articulos/Entreno-Siento-Escribo.vue";
import TalVez from "@/views/articulos/Tal-Vez.vue";
import PageNotFound from "@/views/PageNotFound.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/articulos',
        name: 'Articulos',
        component: Articulos
    }, 
    {
        path: '/entrevistas',
        name: 'Entrevistas',
        component: Entrevistas
    },
    {
        path: '/articulos/apuntes-sobre-tria',
        name: 'apuntes-sobre-tria',
        component: ApuntesSobreTria
    },
    {
        path: '/articulos/el-habito-te-hace-monje',
        name: 'el-habito-te-hace-monje',
        component: ElHabito
    },
    {
        path: '/articulos/a-sol-as',
        name: 'a-sol-as',
        component: ASolAs
    },
    {
        path: '/articulos/lo-ideal-y-lo-real',
        name: 'lo-ideal-y-lo-real',
        component: LoIdealYLoReal
    },
    {
        path: '/articulos/entreno-siento-escribo',
        name: 'entreno-siento-escribo',
        component: EntrenoSientoEscribo
    },
    {
        path: '/articulos/tal-vez',
        name: 'tal-vez',
        component: TalVez
    },
    {
        path: '/:pathMatch(.*)*', // Catch all invalid URLs
        name: 'PageNotFound',
        component: PageNotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
          return {
            el: to.hash,
          };
        }
    },
})

router.afterEach(() => {
    window.scrollTo(0, 0);
});


export default router