<template>
    <div class="elementContainer">
        <div class="aboutMe" id="about">
            <img class="aboutImage" src="@/assets/headshot22.jpg" alt="susanaAvella">
            <div class="aboutInfo">
                <h2 class="aboutHeading">Lic. Susana Avella</h2>
                <p class="aboutPara">Soy Susana Avella, psicóloga con especialización en
                    Psicología Deportiva y Clínica, graduada de la Universidad
                    de Buenos Aires (UBA). Mi pasión es ayudar a las personas a
                    alcanzar su máximo potencial, ya sea en el ámbito deportivo o
                    en su bienestar general.
                </p>
                <a target="_blank" rel="noopener" href="https://wa.me/541159584020?text=Hola%20buenos%20d%C3%ADas%20Susana%21%0A%0AMe%20gustaria%20agendar%20una%20consulta%20psicol%C3%B3gica"><button class="button_style_1">Contacto</button></a>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">

/*-----------------ABOUT ME------------------*/

@use "@/styles/styles.scss" as styles;


.aboutMe{
    @include styles.regularContainer();
    width: 100%;
    padding: 100px 50px 100px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .aboutImage{
        max-height: 420px;
        margin-right: 80px;
        border-radius: 55%;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.8);
    }

    .aboutInfo{
        max-width: 520px;
    }

    .aboutPara{
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 20px;
        text-align: justify;
        line-height: 27px;
    }

    .aboutHeading{
        font-size: 45px;
    }
}

@media (max-width: 768px){
    
    .aboutMe{
        flex-direction: column;
        max-height: 1000px;
        padding: 60px 30px 60px 30px;
        justify-content: center;
        align-items: center;

        .aboutImage{
            max-width: 200px;
            margin-right: 0px;
        }

        .aboutInfo{
            text-align: center;
            width: 100%;
            margin-top: 40px;
        }

        .aboutPara{
            text-align: justify;
        }

        .aboutHeading{
            font-size: 32px;
        }
    }
}

</style>