<template>
  <section class="elementContainer atencion-virtual">
    <div class="atencion-virtual__background">
      <div class="atencion-virtual__content">
        <div class="atencion-virtual__column atencion-virtual__column--left">
          <div class="atencion-virtual__column-icon"><img src="@/assets/home.png" alt=""></div>
          <h3>Atención Presencial</h3>
          <div class="atencion-virtual__column-description">
            <strong>Consultorios en: <br> Capital Federal ⸱ Fco Álvarez <br> Lujan ⸱ La Plata</strong>
          </div>
        </div>
        <div class="atencion-virtual__column atencion-virtual__column--right">
          <div class="atencion-virtual__column-icon"><img src="@/assets/laptop.png" alt=""></div>
          <h3>Atención Virtual</h3>
          <div class="atencion-virtual__column-description">
            <strong>A toda la comunidad de habla hispana, <br> según el huso horario del país de procedencia. <br> Videollamada por Zoom, Meet o WhatsApp</strong>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

@use "@/styles/styles.scss" as styles;

/* --------------- ATENCION VIRTUAL ----------------------*/
.atencion-virtual{

  .atencion-virtual__background{
      background-image: url("@/assets/flags/flags.webp");
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 96px 0px 192px 0px;
      width: 100%;

      @media (max-width: 768px){
        padding: 0px;
      }
      

      &:after{
          content: "";
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.6);
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 19;
      }
      
      .atencion-virtual__title{
          text-align: center;
          font-weight: 400;
          font-size: 30px;
          padding: 0px 0px 48px 0px;
          color: #FFF;
          z-index: 200;
          position: relative;
      }

      a{
          z-index: 200;
      }
  }

  .atencion-virtual__content{
      @include styles.regularContainer();
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      padding: 60px 60px;
      z-index: 20;
      position: relative;
      gap: 90px;
      background-color: #03435acf;
      max-width: 1270px;
      margin-bottom: -15%;

      @media (max-width: 768px){
        margin: 0px;
      }


      @media (max-width: 1200px){
        flex-direction: column;
        gap:20px;
        align-items: center;
      }

      .atencion-virtual__column{
          width: 40%;
          padding: 60px 20px;
          background-color: rgba(255, 255, 255, 0.0);
          border-radius: 2px;
          border: 1px solid #ffffff00;
          position: relative;
          text-align: center;
          color: #FFF;
          gap: 20px;
          display: flex;
          flex-direction: column;

          @media (max-width: 1200px){
            flex-direction: column;
            gap:20px;
            align-items: center;
            width: 100%;
            padding: 20px 20px;
          }

          .atencion-virtual__column-icon{

            border: 2px solid #FFF;
            border-radius: 50%;
            height: 100px;
            width: 100px;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
              filter: invert(1);
              height: 100px;
              padding: 20px;

            }
          }
          

          h3{
            font-size: 28px;
          }

          strong{
            font-size: 18px;
          }

          &--left{
              margin-right: 0px;
          }

          &--right{
              margin-left: 0px;
          }
      }
  }

  .button_style_1{
      z-index: 200;
      position: relative;
  }
}

</style>