<template>
    <header>
        <Opening title="Artículos" customHeight="70vh" backgroundPositionY="130%" :backgroundImage="bannerArticulos"/>
    </header>
    <main>
        <div class="elementContainer">
            <div class="blogsContainer elementContainer">
                <div class="blogItem" v-for="articulo in listadoArticulos" :key="articulo.id">
                    <router-link :to="articulo.link" class="blogCard">
                        <div class="blogImageContainer"><img class="blogImage" :src="require(`@/assets/blogAssets/${articulo.image}`)"></div>
                        <div class="blogInfoContainer">
                            <h4>{{ articulo.title }}</h4>
                            <span>{{ articulo.date }}</span>
                            <span>{{articulo.description}}</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </main>
    <footer>
        <Footer/>
    </footer>
</template>

<script setup>

import Footer from '@/components/Footer/Footer.vue';
import Opening from '@/components/Opening/Opening.vue';
import bannerArticulos from '@/assets/banners/banner_articulos.webp';
import articulos from '@/data/articulos.json';
import { computed } from 'vue';

const listadoArticulos = computed(() => {
    return [...articulos].reverse();
})

</script>

<style scoped>

.footerContainer{
    background-color: #F1F1F1;
}

</style>